<template>
    <div>
        <div class="mask" @click="closeNav" v-if="showNavStatus" @touchmove.prevent></div>
        <div class="nav-container" v-if="showNavStatus" :class="showNavStatus ? 'fade-enter-active' : 'fade-leave-active'">
            <div class="header">
                <i class="iconfont icon-close" @click="closeNav"></i>
            </div>
            <nav>
                <router-link to="/m/">首页</router-link>
                <router-link to="/m/intro">关于itrax</router-link>
                <router-link to="/m/service">itrax服务</router-link>
                <router-link to="/m/customer">客户</router-link>
                <router-link to="/m/case">经典案例</router-link>
                <router-link to="/m/news">itrax新闻</router-link>
                <router-link to="/m/recruitment">诚聘英才</router-link>
            </nav>
        </div>
    </div>
</template>
<script>
export default {
    name: "moble-nav",
    data() {
        return {
            showNavStatus: false,
        };
    },
    methods: {
        closeNav() {
            this.showNavStatus = false;
        },
        showNav() {
            this.showNavStatus = true;
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/icon.css");
.mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 9999998;
}
.nav-container nav .router-link-exact-active {
    color: #0144b1;
}
.nav-container {
    width: 80%;
    background: #f6f6f6;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100%;
    display: flex;
    flex-direction: column;
    z-index: 9999999;
}
.nav-container nav {
    flex: 1;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.nav-container nav a {
    font-size: 16px;
    color: #666666;
    border-bottom: 1px #e5e5e5 solid;
    width: 100%;
    padding: 15px 0;
}
.nav-container .header {
    display: flex;
    justify-content: flex-end;
}
.nav-container .header i {
    padding: 10px;
    margin: 5px;
    color: #333;
}

.fade-enter-active {
    animation: fade-in 0.3s ease forwards;
}
.fade-leave-active {
    animation: fade-in 0.3s ease reverse;
}
@keyframes fade-in {
    0% {
        /* transform: translateX(0); */
        visibility: visible;
        left: 100%;
    }
    100% {
        /* transform: translateX(80%); */
        left: 20%;
    }
}
</style>

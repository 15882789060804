<template>
    <div class="footer-container">
        <div class="col">
            <div><i class="iconfont icon-email"></i>Email:hr@itrax.cn</div>
            <div><i class="iconfont icon-phone1"></i>010-87835788</div>
        </div>
        <div class="col"><i class="iconfont icon-location"></i>北京市朝阳区高碑店乡高井文化园路8号<br />东亿国际传媒产业园区二期元君书苑F1号楼</div>
        <div class="col">Copyright © ITRAX 2015-2020，All Rights Reserved | 京ICP备15004759号</div>
    </div>
</template>

<script>
export default {
    name: "pc-footer",
    components: {},

    data() {
        return {};
    },
};
</script>
<style scoped>
@import url("../../../common/css/icon.css");
.col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 15px;
    font-size: 12px;
}
.col div {
    display: flex;
    align-items: center;
    margin: 0 10px;
}
.col div i {
    margin-right: 5px;
}
.footer-container {
    background: #0048b1;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 15px 0;
}
</style>

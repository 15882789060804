<template>
    <div>
        <header>
            <div class="left">
                <slot name="left"></slot>
            </div>
            <div class="mid">
                <slot name="mid"></slot>
            </div>
            <i class="iconfont icon-menu" @click="showNavHandle"></i>
        </header>
        <Nav ref="nav"></Nav>
    </div>
</template>
<script>
import Nav from "./nav";
export default {
    name: "mobile-header",
    components: { Nav },
    methods: {
        showNavHandle() {
            this.$refs["nav"].showNav();
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/icon.css");
header {
    background: #0a3a85;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99999;
}
header i {
    padding: 10px 15px;
}
header .left {
    min-width: 48px;
}
header .mid {
    font-size: 18px;
    font-weight: 600;
}
</style>

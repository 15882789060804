<template>
    <div>
        <Header>
            <template v-slot:left>
                <img class="logo" src="@/assets/logo.png" />
            </template>
        </Header>
        <div class="col-wrap">
            <img src="@/assets/mobile/home/banner-bg.jpg" />
            <div class="mask banner">
                <h3>提供端到端的 数字化与数据化</h3>
                <h1>营销与传播全价值链整合营销服务商</h1>
                <p>End-to-end Service Provider for Digital Marketing and Full-value-chain Integrated Marketing</p>
            </div>
        </div>
        <div class="col-wrap">
            <img src="@/assets/mobile/home/we-bg.jpg" />
            <div class="mask">
                <img style="width:49%;margin:20px 0 0 0;" src="@/assets/mobile/home/we-title.png" />
                <p style="color:#666;font-size:12px;">数字营销时代的品牌同行者</p>
                <p style="color:#666;font-size:12px;">Brand Explorer in the Age of Digital Marketing</p>
            </div>
            <div class="mask" v-if="configs">
                <swiper :options="picSwiperOption" ref="picSwiper" :delete-instance-on-destroy="true" :cleanup-styles-on-destroy="true">
                    <swiper-slide>
                        <div class="slide-item">
                            <div>{{ configs.duration }}<label>年</label></div>
                            <div>专业深耕</div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide-item">
                            <div>{{ configs.socailScale }}<b>%</b></div>
                            <div>Socail占比</div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide-item">
                            <div>{{ configs.carBusiness }}<b>%</b></div>
                            <div>汽车业务规模</div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide-item">
                            <div>{{ configs.businessScale }}亿<b>+</b></div>
                            <div>营业规模</div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide-item">
                            <div>{{ configs.employee }}<b>+</b></div>
                            <div>公司员工</div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide-item">
                            <div>{{ configs.avgAge }}<label>岁</label></div>
                            <div>员工平均年龄</div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide-item">
                            <div>{{ configs.partner }}<b>+</b></div>
                            <div>世界500强企业合作伙伴</div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide-item">
                            <div>{{ configs.branchCompany }}<label>地</label></div>
                            <div>{{ configs.branchCompanyArea }}</div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide-item">
                            <div>2017<label>年</label></div>
                            <div>加入浙文互联</div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide-item">
                            <div><label>第</label>1<label>梯队</label></div>
                            <div>中国数字营销公司规模</div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="swiper-pagination"></div>
            <!-- <div class="swiper-button-prev"><i class="iconfont icon-more"></i></div>
            <div class="swiper-button-next"><i class="iconfont icon-more"></i></div> -->
        </div>
        <div class="col-wrap case">
            <img class="tit-icon" src="@/assets/mobile/home/case-title.png" />
            <p class="des">不惧颠覆自我的创业情怀和不断激发的创造冲动，用最契合的传播方式， 让每一个案例都成为经典</p>
            <ul>
                <li v-for="item in cases" :key="item.id">
                    <a :href="'/m/case/detail?id=' + item.id">
                        <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,w_166,h_125'" />
                        <h3 class="line2">{{ item.title }}</h3>
                        <p class="line2">{{ item.desc }}</p>
                    </a>
                </li>
            </ul>
            <a href="/m/case" class="blue-btn">查看更多案例+</a>
        </div>
        <div class="col-wrap customer">
            <img class="customer-icon" src="@/assets/mobile/home/customer-tit.png" />
            <p style="font-size:12px;color:#666;margin-bottom:15px;">行业趋势分析、精准策略创意、专业服务执行</p>
            <div class="show"><img v-if="configs" :src="resourceBaseUrl + configs.mobileCustomer" /></div>

            <a class="blue-btn" href="/customer">更多品牌+</a>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
import settings from "@/settings";
export default {
    name: "moble-home",
    components: { Header, Footer },
    data() {
        return {
            resourceBaseUrl: settings.resourceBaseUrl,
            picSwiperOption: {
                // autoplay: {
                //     disableOnInteraction: false
                // },
                //loop: true,
                pagination: {
                    el: ".swiper-pagination",
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
            configs: null,
            cases: [],
        };
    },

    mounted() {
        this.loadConfigs();
        this.loadCase();
    },
    methods: {
        loadConfigs() {
            this.$http.get("/api/index/data").then((res) => {
                this.configs = res.data;
            });
        },
        loadCase() {
            this.$http.get("/api/case/recommend?limit=4").then((res) => {
                this.cases = res.data;
            });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/mobile.css");
@import url("../../../common/css/icon.css");
.slide-item {
    color: #333;
    width: 100%;
    height: 100%;
}
.slide-item > div:first-child {
    font-size: 45px;
    font-weight: bold;
    font-style: italic;
    margin-top: 25%;
}
.slide-item > div:first-child label {
    font-size: 16px;
    font-weight: normal;
}
.slide-item > div:last-child {
    font-size: 17px;
}
.blue-btn {
    margin-bottom: 40px;
}
.customer-icon {
    width: 70%;
    margin: 20px 0 0 0;
}

.customer .show {
    margin: 0 15px 20px 15px;
}
.case ul {
    margin: 15px 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.case ul li {
    background: #fff;
    width: 48%;
    text-align: left;
    margin: 7.5px 0;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 2px 2px 5px rgb(221, 219, 219);
}
.case ul li h3 {
    color: #333333;
    font-size: 14px;
    margin: 10px 5px;
}
.case ul li p {
    color: #666666;
    font-size: 12px;
    margin: 10px 5px;
}
.case ul li img {
    width: 100%;
}
.case .tit-icon {
    width: 75%;
    margin: 20px 0 0 0;
}
.case .des {
    font-size: 12px;
    color: #666666;
    margin: 0 30px;
    text-align: center;
}
.case {
    background: rgb(240, 239, 239);
}
.logo {
    height: 25px;
    margin-left: 15px;
}
.swiper-container {
    width: 100%;
    height: 100%;
}
.swiper-pagination {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
}
.swiper-button-prev,
.swiper-button-next {
    width: 30px;
    height: 30px;
    background: #afafaf;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper-button-prev::after {
    contain: "";
}
.swiper-button-prev i,
.swiper-button-next i {
    color: #000;
}
.swiper-button-prev i {
    transform: rotate(180deg);
}
.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.banner h1 {
    font-size: 20px;
    margin: 20px 0;
}
.banner h3 {
    font-size: 15px;
}
.banner p {
    font-size: 12px;
    margin: 0 20px;
}
</style>
